import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

function PostTime({ settings }) {
  return (
    <div>
      <h1 className="title has-text-left">Post Time</h1>
      <div className="card">
        <div className="card-content">
          <div className="content">
            <div className="m-2 p-2">
              <FontAwesomeIcon
                icon={faClock}
                size="3x"
                className="has-text-primary"
              />
              <div className="subtitle is-pulled-right">
                <span className="tag is-medium is-primary is-light mr-2">
                  {settings.post_time}
                </span>
                <span className="tag is-medium is-success is-light">
                  {settings.time_generation_date}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostTime;

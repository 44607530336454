import React from 'react';

function Input({ name, value, mode, setField, type = 'text' }) {
  return (
    <fieldset disabled={mode === 'readOnly' ? true : false}>
      <div className="field">
        <label className="label is-medium">{name}</label>
        <div className="control">
          <input
            className="input"
            type={type}
            placeholder={name}
            value={value}
            onChange={(e) => setField(e.target.value)}
            readOnly={mode === 'readOnly' ? true : false}
          />
        </div>
      </div>
      <br />
    </fieldset>
  );
}

export default Input;

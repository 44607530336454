import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import LoginContext from '../Contexts/LoginContext';

import { get_keys, set_keys } from '../Services/api';
import Input from './Input';
import Welcome from './Welcome';

function Keys() {
  const { loggedIn, setLoggedIn } = useContext(LoginContext);

  const [mode, setMode] = useState('readOnly');
  const [spinnerLoading, setSpinnerLoading] = useState(true);

  const [fbToken, setFbToken] = useState('');
  const [fbPageId, setFbPageId] = useState('');
  const [fbAlbumId, setFbAlbumId] = useState('');

  const [telegramToken, setTelegramToken] = useState('');
  const [telegramUserId, setTelegramUserId] = useState('');

  const [regCode, setRegCode] = useState('');
  const [jwtSecret, setJwtSecret] = useState('');

  const [message, setMessage] = useState('');

  useEffect(() => {
    async function fetch_keys() {
      try {
        const res = await get_keys();

        if (res.error) {
          localStorage.clear();
          setLoggedIn(false);
          return <Welcome />;
        }

        const {
          REG_CODE,
          FB_ALBUM_ID,
          FB_PAGE_ID,
          FB_TOKEN,
          TELEGRAM_TOKEN,
          TELEGRAM_USER_ID,
          JWT_SECRET
        } = res;

        setFbToken(FB_TOKEN);
        setFbPageId(FB_PAGE_ID);
        setFbAlbumId(FB_ALBUM_ID);

        setTelegramToken(TELEGRAM_TOKEN);
        setTelegramUserId(TELEGRAM_USER_ID);

        setJwtSecret(JWT_SECRET);
        setRegCode(REG_CODE);

        return setSpinnerLoading(false);
      } catch (error) {
        return setSpinnerLoading(true);
      }
    }

    fetch_keys();
  }, [message, setLoggedIn]);

  async function saveKeys() {
    setMode('readOnly');

    const data = {
      FB_TOKEN: fbToken,
      FB_PAGE_ID: fbPageId,
      FB_ALBUM_ID: fbAlbumId,
      TELEGRAM_TOKEN: telegramToken,
      TELEGRAM_USER_ID: telegramUserId,
      JWT_SECRET: jwtSecret,
      REG_CODE: regCode
    };

    const res = await set_keys(data);
    setMessage(res);

    toast(res.message);
  }

  if (!loggedIn) {
    return <Welcome />;
  }

  if (spinnerLoading) {
    return (
      <div className="has-text-centered">
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={100}
          width={100}
          visible={spinnerLoading}
        />
      </div>
    );
  }

  return (
    <div>
      <h1 className="title has-text-left">Keys</h1>

      <div className="card mb-4">
        <div className="card-content">
          <div className="content">
            <div className="m-2 p-2">
              <Input
                name={'Facebook Token'}
                value={fbToken}
                mode={mode}
                setField={setFbToken}
              />
              <Input
                name={'Facebook Page ID'}
                value={fbPageId}
                mode={mode}
                type="number"
                setField={setFbPageId}
              />
              <Input
                name={'Facebook Album ID'}
                value={fbAlbumId}
                mode={mode}
                type="number"
                setField={setFbAlbumId}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="card mb-4">
        <div className="card-content">
          <div className="content">
            <div className="m-2 p-2">
              <Input
                name={'Telegram Token'}
                value={telegramToken}
                mode={mode}
                setField={setTelegramToken}
              />
              <Input
                name={'Telegram User ID'}
                value={telegramUserId}
                mode={mode}
                type="number"
                setField={setTelegramUserId}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="card mb-4">
        <div className="card-content">
          <div className="content">
            <div className="m-2 p-2">
              <Input
                name={'JWT Secret'}
                value={jwtSecret}
                mode={mode}
                setField={setJwtSecret}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="card mb-4">
        <div className="card-content">
          <div className="content">
            <div className="m-2 p-2">
              <Input
                name={'Registration Code'}
                value={regCode}
                mode={mode}
                setField={setRegCode}
              />
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="has-text-centered mt-6 mb-2">
        {mode === 'readOnly' ? (
          <button
            className="button is-medium is-warning"
            onClick={() => setMode('edit')}
          >
            Edit
          </button>
        ) : (
          <button
            className="button is-medium is-success"
            onClick={() => saveKeys()}
          >
            Save
          </button>
        )}
      </div>

      <br />
    </div>
  );
}

export default Keys;

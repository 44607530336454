import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import LoginContext from '../Contexts/LoginContext';

import { get_logs } from '../Services/api';
import Welcome from './Welcome';

function Logs() {
  const { loggedIn, setLoggedIn } = useContext(LoginContext);

  const [logs, setLogs] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(true);

  useEffect(() => {
    async function fetch_logs() {
      try {
        const res = await get_logs();

        if (res.error) {
          localStorage.clear();
          setLoggedIn(false);
          return <Welcome />;
        }

        setLogs(res);
        return setSpinnerLoading(false);
      } catch (error) {
        return setSpinnerLoading(true);
      }
    }

    fetch_logs();
  }, [setLoggedIn]);

  if (!loggedIn) {
    return <Welcome />;
  }

  const getDateAndTime = (dateStr) => {
    const date = new Date(dateStr);

    return `${date.toDateString()}, ${date.toLocaleTimeString()}`;
  };

  if (spinnerLoading) {
    return (
      <div className="has-text-centered">
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={100}
          width={100}
          visible={spinnerLoading}
        />
      </div>
    );
  }

  return (
    <div>
      <h1 className="title has-text-left">Logs</h1>
      <div>
        {logs.map((log) => (
          <div className="card mb-4 p-2" key={log._id}>
            <a href={log.post_link} target="_blank" rel="noreferrer">
              <div className="card-content subtitle has-text-link-dark ">
                {getDateAndTime(log.createdAt)}
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Logs;

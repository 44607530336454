import React from 'react';
import { Link } from 'react-router-dom';

function Welcome() {
  return (
    <div className="has-text-centered">
      <h1 className="title has-text-centered">Welcome To FB-BOT</h1>
      <figure className="image is-128x128 is-inline-block mb-4 pb-2">
        <img src="/logo512.png" alt="Logo" />
      </figure>
      <h2 className="has-text-centered is-size-5">
        Please <Link to="/register">Register</Link> or{' '}
        <Link to="/login">Login</Link> to continue
      </h2>
    </div>
  );
}

export default Welcome;

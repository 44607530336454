import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import LoginContext from '../Contexts/LoginContext';
import { get_settings } from '../Services/api';

import PostStatus from './PostStatus';
import PostTime from './PostTime';
import Settings from './Settings';
import Welcome from './Welcome';

function Home() {
  const { loggedIn, setLoggedIn } = useContext(LoginContext);

  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    async function fetch_settings() {
      try {
        const data = await get_settings();
        if (data.error) {
          localStorage.clear();
          setLoggedIn(false);
          return <Welcome />;
        }

        const token = localStorage.getItem('token');

        if (token && token.length > 0) {
          setSettings(data);
          setSpinnerLoading(false);
        }
      } catch (error) {
        setSpinnerLoading(true);
      }
    }

    fetch_settings();
  }, [message, loggedIn, setLoggedIn]);

  if (!loggedIn) {
    return <Welcome />;
  }

  if (spinnerLoading) {
    return (
      <div className="has-text-centered">
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={100}
          width={100}
          visible={spinnerLoading}
        />
      </div>
    );
  }

  return (
    <div>
      <PostStatus settings={settings} />

      <br />
      <br />

      <Settings settings={settings} setMessage={setMessage} />

      <br />
      <br />

      <PostTime settings={settings} />

      <br />
      <br />
    </div>
  );
}

export default Home;

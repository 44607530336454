import axios from 'axios';
const api_endpoint = process.env.REACT_APP_SERVER;

const api = axios.create({
  baseURL: api_endpoint,
  timeout: 3000,
  headers: { 'x-access-token': localStorage.getItem('token') }
});

async function login(data) {
  try {
    const res = await api.post(`${api_endpoint}/login`, data);

    localStorage.setItem('token', res.data.token);
    localStorage.setItem('name', res.data.name);

    return res.data;
  } catch (error) {
    return { error: error.response.data.message };
  }
}

async function register(data) {
  try {
    const res = await api.post(`${api_endpoint}/register`, data);

    localStorage.setItem('token', res.data.token);
    localStorage.setItem('name', res.data.name);

    return res.data;
  } catch (error) {
    return { error: error.response.data.message };
  }
}

export { login, register };

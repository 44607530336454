import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import LoginContext from "../Contexts/LoginContext";
import { login } from "../Services/auth";
import Input from "./Input";

function Login() {
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { loggedIn, setLoggedIn } = useContext(LoginContext);

  async function handleLogin() {
    const res = await login({ username, password });

    if (res.error) {
      setError(res.error);
    }

    if (res.token) {
      setLoggedIn(true);
    }
  }

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <form onSubmit={(e) => e.preventDefault() && handleLogin()}>
      <h1 className="title has-text-left">Log In</h1>
      <div className="card mb-4">
        <div className="card-content">
          <div className="content">
            <div className="m-2 p-2">
              <Input
                name={"Username"}
                value={username}
                setField={setUsername}
              />
              <Input
                name={"Password"}
                value={password}
                type="password"
                setField={setPassword}
              />
            </div>
          </div>

          {error ? (
            <article className="message is-danger">
              <div className="message-body">{error}</div>
            </article>
          ) : null}

          <div className="has-text-centered">
            <button
              className="button is-medium is-success"
              onClick={() => handleLogin()}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Login;

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faPaperPlane,
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

function PostStatus({ settings }) {
  return (
    <div>
      <h1 className="title has-text-left">Post Status</h1>
      <div className="card">
        <div className="card-content">
          <div className="content">
            <div className="m-2 p-2">
              <FontAwesomeIcon
                icon={faPaperPlane}
                size="3x"
                className="has-text-success"
              />

              <span className="is-pulled-right is-clickable">
                {settings.posted ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="3x"
                    className="has-text-success"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    size="3x"
                    className="has-text-danger"
                  />
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostStatus;

import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import LoginContext from '../Contexts/LoginContext';
import { register } from '../Services/auth';
import Input from './Input';

function Register() {
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');

  const { loggedIn, setLoggedIn } = useContext(LoginContext);

  async function handleRegister() {
    const res = await register({ name, username, password, code });

    if (res.error) {
      setError(res.error);
    }

    if (res.token) {
      setLoggedIn(true);
    }
  }

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <h1 className="title has-text-left">Register</h1>
      <div className="card mb-4">
        <div className="card-content">
          <div className="content">
            <div className="m-2 p-2">
              <Input name={'Name'} value={name} setField={setName} />
              <Input
                name={'Username'}
                value={username}
                setField={setUsername}
              />
              <Input
                name={'Password'}
                value={password}
                type="password"
                setField={setPassword}
              />
              <Input
                name={'Registration Code'}
                value={code}
                type="number"
                setField={setCode}
              />
            </div>
          </div>

          {error ? (
            <article className="message is-danger">
              <div className="message-body">{error}</div>
            </article>
          ) : null}

          <div className="has-text-centered">
            <button
              className="button is-medium is-success"
              onClick={() => handleRegister()}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;

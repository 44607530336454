import axios from 'axios';
const api_endpoint = process.env.REACT_APP_SERVER;

const api = axios.create({
  baseURL: api_endpoint,
  timeout: 3000,
  headers: { 'x-access-token': localStorage.getItem('token') }
});

async function get_logs() {
  try {
    const logs = await api.get(`/logs`, {
      headers: { 'x-access-token': localStorage.getItem('token') }
    });

    return logs.data;
  } catch (error) {
    return { error: true };
  }
}

async function get_settings() {
  try {
    const res = await api.get(`/settings`, {
      headers: { 'x-access-token': localStorage.getItem('token') }
    });
    return res.data;
  } catch (error) {
    return { error: true };
  }
}

async function set_settings(setting) {
  try {
    const res = await api.post(`/settings`, setting, {
      headers: { 'x-access-token': localStorage.getItem('token') }
    });
    return res.data;
  } catch (error) {
    return { error: true };
  }
}

async function get_keys() {
  try {
    const res = await api.get(`/keys`, {
      headers: { 'x-access-token': localStorage.getItem('token') }
    });
    return res.data;
  } catch (error) {
    return { error: true };
  }
}

async function set_keys(keys) {
  try {
    const res = await api.post(`/keys`, keys, {
      headers: { 'x-access-token': localStorage.getItem('token') }
    });
    return res.data;
  } catch (error) {
    return { error: true };
  }
}

export { get_logs, get_settings, set_settings, get_keys, set_keys };

import React from 'react';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTelegram } from '@fortawesome/free-brands-svg-icons';
import {
  faToggleOn,
  faToggleOff,
  faDesktop
} from '@fortawesome/free-solid-svg-icons';

import { set_settings } from '../Services/api';

function Settings({ settings, setMessage }) {
  async function handle_bot_status(update) {
    if (update.bot_status === 'running') {
      const res = await set_settings({ bot: 'disable' });

      setMessage(res);
      toast(res.message);
    }

    if (update.bot_status === 'stopped') {
      const res = await set_settings({ bot: 'enable' });

      setMessage(res);
      toast(res.message);
    }
  }

  async function handle_posting_status(update) {
    if (update.posting_status) {
      const res = await set_settings({ posting: 'disable' });

      setMessage(res);

      toast(res.message);
    }

    if (!update.posting_status) {
      const res = await set_settings({ posting: 'enable' });

      setMessage(res);
      toast(res.message);
    }
  }

  async function handle_telegram_status(update) {
    if (update.telegram_status) {
      const res = await set_settings({ telegram: 'disable' });

      setMessage(res);
      toast(res.message);
    }

    if (!update.telegram_status) {
      const res = await set_settings({ telegram: 'enable' });

      setMessage(res);
      toast(res.message);
    }
  }

  return (
    <div>
      <h1 className="title has-text-left">Settings</h1>
      <div className="card">
        <div className="card-content">
          <div className="content">
            <div className="m-2 p-2">
              <FontAwesomeIcon
                icon={faDesktop}
                size="4x"
                className={
                  settings.bot_status === 'running'
                    ? 'has-text-success-dark'
                    : 'has-text-dark'
                }
              />
              <span
                className="is-pulled-right is-clickable"
                onClick={() =>
                  handle_bot_status({ bot_status: settings.bot_status })
                }
              >
                {settings.bot_status === 'running' ? (
                  <FontAwesomeIcon
                    icon={faToggleOn}
                    size="3x"
                    className="has-text-success-dark"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faToggleOff}
                    size="3x"
                    className="has-text-dark"
                  />
                )}
              </span>
            </div>

            <div className="m-2 p-2">
              <FontAwesomeIcon
                icon={faFacebook}
                size="4x"
                className={
                  settings.enabled ? 'has-text-link-dark' : 'has-text-dark'
                }
              />
              <span
                className="is-pulled-right is-clickable"
                onClick={() =>
                  handle_posting_status({ posting_status: settings.enabled })
                }
              >
                {settings.enabled ? (
                  <FontAwesomeIcon
                    icon={faToggleOn}
                    size="3x"
                    className="has-text-link-dark"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faToggleOff}
                    size="3x"
                    className="has-text-dark"
                  />
                )}
              </span>
            </div>

            <div className="m-2 p-2">
              <FontAwesomeIcon
                icon={faTelegram}
                size="4x"
                className={
                  settings.telegram ? 'has-text-info' : 'has-text-dark'
                }
              />
              <span
                className="is-pulled-right is-clickable"
                onClick={() =>
                  handle_telegram_status({
                    telegram_status: settings.telegram
                  })
                }
              >
                {settings.telegram ? (
                  <FontAwesomeIcon
                    icon={faToggleOn}
                    size="3x"
                    className="has-text-info"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faToggleOff}
                    size="3x"
                    className="has-text-dark"
                  />
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;

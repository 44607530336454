import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LoginContext from '../Contexts/LoginContext';

function Navbar() {
  const { loggedIn, setLoggedIn } = useContext(LoginContext);
  const [isActive, setIsActive] = useState(false);

  function logout() {
    localStorage.clear();
    setLoggedIn(false);
    setIsActive(false);
  }

  return (
    <div>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <NavLink className="navbar-item has-text-weight-bold" to="/">
            FB-BOT
          </NavLink>

          <div
            onClick={() => setIsActive(!isActive)}
            role="button"
            className={isActive ? 'navbar-burger is-active' : 'navbar-burger'}
            aria-label="menu"
            aria-expanded="false"
            data-target="mobile-menu"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>

        {loggedIn && (
          <div
            id="mobile-menu"
            className={isActive ? 'navbar-menu is-active' : 'navbar-menu'}
          >
            <div className="navbar-end">
              <div className="navbar-item">
                <NavLink
                  className="navbar-item is-capitalized"
                  to="/"
                  onClick={() => setIsActive(false)}
                >
                  {localStorage.getItem('name')}
                </NavLink>
                <NavLink
                  className="navbar-item"
                  to="/logs"
                  onClick={() => setIsActive(false)}
                >
                  Logs
                </NavLink>
                <NavLink
                  className="navbar-item"
                  to="/keys"
                  onClick={() => setIsActive(false)}
                >
                  Keys
                </NavLink>
                <NavLink
                  className="button navbar-item is-warning"
                  to="/logout"
                  onClick={() => logout()}
                >
                  Logout
                </NavLink>
              </div>
            </div>
          </div>
        )}

        {!loggedIn && (
          <div
            id="mobile-menu"
            className={isActive ? 'navbar-menu is-active' : 'navbar-menu'}
          >
            <div className="navbar-end">
              <div className="navbar-item">
                <NavLink
                  className="navbar-item"
                  to="/register"
                  onClick={() => setIsActive(false)}
                >
                  Register
                </NavLink>
                <NavLink
                  className="navbar-item"
                  to="/login"
                  onClick={() => setIsActive(false)}
                >
                  Login
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
}

export default Navbar;

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import React, { useEffect, useState } from 'react';

import 'bulma/css/bulma.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Logs from './Components/Logs';
import Keys from './Components/Keys';
import Login from './Components/Login';
import LoginContext from './Contexts/LoginContext';
import Register from './Components/Register';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    async function fetch_settings() {
      try {
        const token = localStorage.getItem('token');

        if (token && token.length > 0) {
          setLoggedIn(true);
        }
      } catch (error) {
        setLoggedIn(false);
      }
    }

    fetch_settings();
  }, [loggedIn]);

  return (
    <LoginContext.Provider value={{ loggedIn, setLoggedIn }}>
      <Router>
        <Navbar />

        <div className="m-4">
          <div className="container">
            <ToastContainer
              position="top-right"
              transition={Zoom}
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

            <Switch>
              <Route exact path="/register">
                <Register />
              </Route>

              <Route exact path="/login">
                <Login />
              </Route>

              <Route exact path="/logs">
                <Logs />
              </Route>

              <Route exact path="/keys">
                <Keys />
              </Route>

              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </LoginContext.Provider>
  );
}

export default App;
